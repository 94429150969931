import React from 'react'
import Logo from '../../assets/logo.svg'
import { QRCode } from 'react-qrcode-logo'

function Landing() {
    return (
        <section className='w-full min-h-screen bg-[#161F3B] flex flex-col'>
            <div className='flex items-center gap-2 p-5 cursor-pointer'>
                <img src={Logo} alt="" />
                <h1 className='text-3xl font-medium'>Aqqess</h1>
            </div>

            <div className='flex-grow flex p-5 flex-col items-center justify-center gap-2 md:gap-6 -mt-16'>
                <h1 className='font-medium text-center text-2xl sm:text-3xl md:text-4xl lg:text-5xl mb-3'>You can aqqess this only from your phone!</h1>
                <div className='bg-white rounded-3xl overflow-hidden w-[300px] h-[300px] grid place-items-center'>
                    <QRCode
                        ecLevel="L"
                        qrStyle="dots"
                        size={270}
                        value={'https://emp.aqqess.me'}
                        logoHeight={50}
                        logoWidth={50}
                    />
                </div>
                <p className='text-lg sm:text-xl md:text-2xl xl:text-3xl text-center text-gray-300'>Scan this QR code to get started</p>
                <button className='py-1.5 px-6 bg-[#455BA0] rounded-xl lg:text-lg' onClick={() => window.location.replace("https://www.aqqess.me")}>
                    Return to Home Page
                </button>
            </div>
        </section>
    )
}

export default Landing