import React, { useEffect, useState } from 'react'
import Logo from '../../assets/logo.svg'
import Threadality from '../../assets/threadality.svg'
import { useHistory } from 'react-router-dom'
import OTPInput from 'react-otp-input'
import { useSendOtp, useVerifyOtp } from '../../hooks'

const hasOnlyNumber = (str) => /^\d+$/.test(str)

function AppVerifyOTP() {
  const history = useHistory()
  const [otp, setOtp] = useState('');
  const [timer, setTimer] = useState(0); // Initial timer value
  const [showSendAgain, setShowSendAgain] = useState(false);

  useEffect(() => {
    if (!history.location.state?.email || !history.location.state?.type) {
      window.location.replace("/404")
    }
  }, [history.location.state])

  useEffect(() => {
    let interval;

    if (timer > 0) {
      interval = setInterval(() => {
        setTimer(timer - 1);
      }, 1000);
    } else {
      setShowSendAgain(true);
      clearInterval(interval);
    }

    return () => clearInterval(interval); // Cleanup the interval
  }, [timer]);

  const handleSendAgainClick = () => {
    setTimer(30); // Reset the timer to 20 seconds
    setShowSendAgain(false); // Hide the "Send Again" button
  };

  const { mutate: verifyOtp, isLoading } = useVerifyOtp(() => history.location.state?.register ? history.push("/") : history.push("/reset-password", { email: history.location.state?.email, type: history.location.state?.type }))

  let { mutate } = useSendOtp()

  const handleSubmit = (e) => {
    e.preventDefault();
    verifyOtp({ email: history.location.state?.email, otp: otp, type: 'user' })
  }

  const sendAgain = () => {
    mutate({ email: history.location.state?.email, type: 'user', flow: 'resend' })
    handleSendAgainClick();
  }


  const renderInput = (inputProps) => (
    <>
      <div className='bg-transparent w-full h-10 text-center'>
        <input {...inputProps} className="bg-transparent w-full h-10 outline-none text-2xl" />
        <div className='w-full h-1 bg-[#E8C3FF] rounded-full'></div>
      </div>
    </>
  );

  return (
    <section className='bg-gray flex flex-col p-5 h-[100dvh]'>
      <div className='flex items-end cursor-pointer' onClick={() => history.push("/")}>
        <img src={Logo} className='w-10' alt="" />
        <h1 className='text-xl font-medium'>Aqqess</h1>
      </div>

      <div className='flex-grow flex flex-col w-full max-w-xs mx-auto'>
        <form onSubmit={handleSubmit} className='flex flex-col gap-3 flex-grow justify-center'>
          <h1 className='text-3xl font-medium text-center mb-20'>We sent you something</h1>
          <div className='w-full max-w-xs'>
            <OTPInput
              value={otp}
              onChange={(txt) => setOtp(txt)}
              numInputs={6}
              containerStyle="w-full max-w-xs gap-2 p-3 rounded-lg"
              renderInput={renderInput}
            />
          </div>

          <div className='flex flex-col justify-end items-end gap-1 mt-2'>
            <button disabled={otp?.length !== 6 || !hasOnlyNumber(otp) || isLoading} type='submit' className='bg-[#B338FF] py-1.5 px-6 rounded-xl'>
              <span>Submit</span>
            </button>
            <p>Did not receive OTP? {showSendAgain ? <span onClick={sendAgain} className='hover:text-yellow-300 cursor-pointer'>Send Again</span> : <span>Wait for {timer}s</span>}</p>
          </div>
        </form>
      </div>

      <div>
        <img src={Threadality} className='h-20 mx-auto' alt="" />
      </div>

    </section>
  )
}

export default AppVerifyOTP