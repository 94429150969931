import { Switch, Route } from 'react-router-dom';
import AppHome from './components/App/AppHome';
import AppLogin from './components/App/AppLogin';
import AppSignUp from './components/App/AppSignup';
import AppVerifyOTP from './components/App/AppVerifyOTP';
import { useInitialLoad } from './hooks';
import Loader from './components/Reusable/Loader';
import UserRoute from './UserRoute';
import NotFound from './components/Reusable/NotFound';
import SubDomain from './components/App/SubDomain';
import Branch from './components/App/Branch';

function Routes() {

    let { isLoading } = useInitialLoad()

    if (isLoading) {
        return <Loader />
    }

    return (
        <Switch>

            <UserRoute exact path="/" component={() => <SubDomain />} />
            <UserRoute exact path="/branch" component={() => <Branch />} />
            <UserRoute exact path="/branch/:branchName" component={() => <AppHome />} />
            <UserRoute exact path="/branch/:branchName/login" component={AppLogin} />
            <UserRoute exact path="/branch/:branchName/signup" component={AppSignUp} />
            <UserRoute exact path="/verify" component={AppVerifyOTP} />

            <Route exact path="*" component={NotFound} />
        </Switch>
    );
}

export default Routes;
