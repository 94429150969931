import { useEffect } from "react"
import { toast } from "react-hot-toast"
import { QueryClient, useMutation, useQuery, useQueryClient } from "react-query"
import { useDispatch } from "react-redux"
import { UserService } from "../services/user.service"
import { setCompany, setUserInfo, setUserSectors } from "../slices/userSlice"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"

export const useInitialLoad = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    let loc = window.location.hostname?.split(".")
    let subdomain = loc[0]

    let { isLoading: loading, data: companyData, refetch: validateCompany } = useQuery('validateCompany', () => UserService.getCompany(subdomain), {
        enabled: false,
        retry: false,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        onSuccess: res => {
            if (res?.success && !res?.data) {
                if (loc[1] == 'emp') {
                    // history.push('/')
                }
                // if (subdomain == 'aqqess') {
                // } else
                //     if (subdomain == 'aqqess') {
                //     } else
                //         if (subdomain == 'localhost') {
                //         } else {
                //             history.push('/branch')
                //         }
            }
        },
        onError: (e) => console.log(e)
    })

    useEffect(() => {
        if (loc[1] == 'emp') {
            validateCompany()
        }
    }, [subdomain])


    let { isLoading, data, isError, error, isFetching, refetch: validateToken } = useQuery('validateToken', UserService.auth, {
        // enabled: false,
        retry: false,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
    })

    const { refetch } = useQuery('getSectors', UserService.getSector, {
        enabled: false,
        retry: false,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        onSuccess: res => { dispatch(setUserSectors(res?.data)) },
        onError: (er) => console.log(er)
    })

    useEffect(() => {
        if (companyData?.data) {
            // validateToken();
            dispatch(setCompany(companyData?.data));
        }
    }, [companyData])

    useEffect(() => {
        if (data?.data) {
            refetch();
            dispatch(setUserInfo(data?.data));
            history.push(`/branch/${data?.data?.branch}`)
        }
    }, [data])

    return { isLoading: isLoading || loading, isError, error, isFetching }
}

export const useSendOtp = (callback) => {
    return useMutation(UserService.sendOtp, {
        onSuccess: (response) => {
            if (response?.success === true) {
                console.log("OTP ", response.data.otp)
                toast?.success("OTP sent successfully!!", { id: 'success' });
                if (typeof callback === 'function') {
                    callback()
                }
            } else if (response?.message) {
                toast.error(response?.message, { id: 'error' })
            }
        },
        onError: (e) => {
            toast.error("Oops! Unable to send OTP", { id: 'error' })
        }
    })
}

export const useVerifyOtp = (callback) => {
    return useMutation(UserService.verifyOtp, {
        onSuccess: (response) => {
            if (response?.success === true) {
                toast?.success("OTP verified successfully!!", { id: 'success' });
                if (typeof callback == 'function')
                    callback();
            } else if (response?.message) {
                toast.error(response?.message, { id: 'error' })
            }
        },
        onError: (e) => {
            toast.error("Oops! Unable to send OTP", { id: 'error' })
        }
    })
}

export const useLogin = (callback) => {
    return useMutation(UserService.login, {
        onSuccess: (response) => {
            if (response?.success === true) {
                if (response.isActive) {
                    localStorage.setItem('app_aqqess_token', response.accessToken);
                    window.location.replace("/")
                }
                else
                    toast("Please verify your email", { id: 'info' })
                if (typeof callback == 'function')
                    callback();
            } else if (response?.message) {
                toast.error(response?.message, { id: 'error' })
            }
        },
        onError: (e) => {
            console.log(e)
            toast.error("Oops! Unable to login user", { id: 'error' })
        }
    })
}

export const useUpdateProfile = (callback) => {
    return useMutation(UserService.updateProfile, {
        onSuccess: (response) => {
            if (response?.success === true) {
                toast?.success("Profile Updated Successfully!", { id: 'success' });
                if (typeof callback == 'function')
                    callback();
            }
            else if (response?.message) {
                toast.error(response?.message, { id: 'error' })
            }
        },
        onError: (e) => {
            console.log(e)
            toast.error("Oops! Something went wrong", { id: 'error' })
        }
    })
}
export const useResetPassword = (callback) => {
    return useMutation(UserService.resetPassword, {
        onSuccess: (response) => {
            toast?.success("Password Updated Successfully!", { id: 'success' })
            if (response?.success === true) {
                if (typeof callback === 'function')
                    callback(response);
            }
            else if (response?.message) {
                toast.error(response?.message, { id: 'error' })
            }
        },
        onError: (e) => {
            console.log(e)
            toast.error("Oops! Something went wrong", { id: 'error' })
        }
    })
}

export const useUpdatePassword = (callback) => {
    return useMutation(UserService.updatePassword, {
        onSuccess: (response) => {
            if (response?.success === true) {
                toast?.success("Password Updated Successfully!", { id: 'success' })
                if (typeof callback === 'function')
                    callback(response);
            }
            else if (response?.message) {
                toast.error(response?.message, { id: 'error' })
            }
        },
        onError: (e) => {
            console.log(e)
            toast.error("Oops! Something went wrong", { id: 'error' })
        }
    })
}


export const useRegister = (callback) => {
    let { mutate } = useSendOtp()
    return useMutation(UserService.register, {
        onSuccess: (response) => {
            if (response?.success === true) {

                if (!response.isActive) {
                    mutate({ email: response?.data?.email, type: 'user', flow: 'register' })
                }
                else
                    toast.success("Registered Successfully!", { id: 'success' })
                if (typeof callback === 'function')
                    callback(response);
            }
            else if (response?.message) {
                toast.error(response?.message, { id: 'error' })
            }
        },
        onError: (e) => {
            console.log(e)
            toast.error("Oops! Something went wrong", { id: 'error' })
        }
    })
}


export const useTempRequest = (callback) => {
    const queryClient = useQueryClient()
    return useMutation(UserService.temporaryRequest, {
        onSuccess: (response) => {
            if (response?.success === true) {
                queryClient.invalidateQueries('getTemporaryRequest')
                if (typeof callback === 'function')
                    callback(response);
            }
            else if (response?.message) {
                toast.error(response?.message, { id: 'error' })
            }
        },
        onError: (e) => {
            console.log(e)
            toast.error("Oops! Something went wrong", { id: 'error' })
        }
    })
}

export const useVisitorRequest = (callback) => {
    const queryClient = useQueryClient()
    return useMutation(UserService.visitorRequest, {
        onSuccess: (response) => {
            if (response?.success === true) {
                queryClient.invalidateQueries('getVisitorRequest')
                if (typeof callback === 'function')
                    callback(response);
            }
            else if (response?.message) {
                toast.error(response?.message, { id: 'error' })
            }
        },
        onError: (e) => {
            console.log(e)
            toast.error("Oops! Something went wrong", { id: 'error' })
        }
    })
}